import "./go.icon.scss";
import icons from "./go.icons";

export default {
  beforeRender: function() {
    this.name = this.attrs.name;
    this.svg = this.getSVG(this.name);
  },
  render: function() {
    this.innerHTML = this.svg;
  },
  getSVG: function() {
    this.lowename = this.name && this.name.replace(/-/g, "_");

    if (icons[this.name]) {
      return icons[this.name];
    }

    if (icons[this.lowename]) {
      return icons[this.lowename];
    }

    return "?";
  },
};
